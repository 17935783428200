<template>
    <div>
        <div class="card events-card border-none" >
            <div class="card-table">
            <div class="content is-color-palete-blue-4">
                <div class="field is-color-palete-blue-4">
                    <label class="label has-text-dark">Search projects</label>
                    <div class="control">
                        <input v-model="search_proj_table_text" class="input is-info is-small" type="text" placeholder="Project name">
                    </div>
                </div>
                <div class="left-dashboard-table-wrap custom_scrollbar-1" style="">
                    <table class="table white-table is-color-palete-blue-4 dashboard-left-pannel  is-hoverable is-narrow is-fullwidth is-size-7" style="table-layout: fixed;  width: 100%;">
                        <tbody>
                            <tr>
                                <th @click.prevent="() => {$parent.selected_project_id = 0}" class="table-align-center-center is-clickable" width="6%">
                                    <i v-if="$parent.selected_project_id" class="fas fa-circle"></i>
                                    <i v-else class="fas fa-dot-circle"></i>
                                </th>
                                <th class="has-text-weight-semibold">{{$t("project_name") }}</th>
                                <th>{{$t("start_date") }} - {{$t("end_date") }} </th>
                                <th class="table-align-center-center" width="6%"><i class="fas fa-truck" title="Request jobs"></i></th>
                                <th class="table-align-center-center" width="6%"><i class="fas fa-warehouse" title="Warehouse jobs"></i></th>
                                <th class="table-align-center-center" width="6%">
                                    <i class="fas fa-check"></i>
                                </th>
                                <th class="table-align-center-center" width="6%">
                                    <!-- <i class="fas fa-external-link-alt"></i> -->
                                </th>
                            </tr>
                            <tr v-for="(proj, index) in first_10" :key="index" :class="{'is-background-palete-blue-3' : proj.IdProject == $parent.selected_project_id}" class="">
                                <td @click="select_project(proj.IdProject)" class="table-align-center-center is-clickable">
                                    <input type="radio" :value="proj.IdProject" v-model="$parent.selected_project_id" class="is-clickable">
                                </td>
                                <td class="is-clickable" @click="select_project(proj.IdProject)">
                                    <p class="m-0 has-text-weight-semibold"> {{proj.Number}} </p>
                                    <span> {{proj.p_caption}} </span>
                                </td>
                                <td class="is-clickable" @click="select_project(proj.IdProject)">
                                    <p class="m-0">
                                        {{proj.StartDate | customdate}}
                                    </p>
                                    <p class="m-0 has-text-right">
                                        {{proj.EndDate | customdate}}
                                    </p>
                                </td>
                                <td @click="select_project(proj.IdProject)" class="table-align-center-center is-clickable">{{proj.request_job}}</td>
                                <td @click="select_project(proj.IdProject)" class="table-align-center-center is-clickable">{{proj.warehouse_job}}</td>
                                <td @click="select_project(proj.IdProject)" class="table-align-center-center is-clickable">{{proj.truck_check_job}}</td>
                                <td class="table-align-center-center is-clickable is-clickable">
                                    <span @click.prevent="open_project(proj)">
                                        <i class="fas fa-external-link-alt"></i>
                                    </span>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>21-00XY</td>
                                <td>Absperrungen Xletix Wuppertal</td>
                                <td>22th June 2022</td>
                                <td>3rd August 2022</td>
                            </tr> -->
                        </tbody>
                    </table>

                </div>
            </div>
            </div>
            <footer v-if=" first_10.length != this.$parent.modules_data.my_project_list.length " class="card-footer p-1 ">
                <a @click.prevent="toggle_limit()" href="#" class="card-footer-item p-0 has-text-white">{{$t("load_more")}}</a>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: 10,
            default_limit: 10,
            search_proj_table_text: ''
        }
    },
    computed:{
            // get_dashboard_projects_list(){ return this.$parent.modules_data.my_project_list },
            first_10(){
                return this.$parent.modules_data.my_project_list
                .filter((obj) =>{
                    if(this.search_proj_table_text != ''){
                        return obj.p_caption.toLowerCase().includes(this.search_proj_table_text.toLowerCase())
                        // return JSON.stringify(obj).toLowerCase().includes(this.search_proj_table_text.toLowerCase())
                    } 
                    else return true
                })
            }
    },
    methods: {
        toggle_limit(){
            this.limit =  this.limit + this.default_limit
            // this.limit = (this.limit == this.default_limit ? this.$parent.modules_data.my_project_list.length  : this.default_limit) 
        },
        open_project(p){
            console.log('p', p)
            let routeData = this.$router.resolve({
                path: "/delivery-request/" + p.IdProject,
                params: {id: "sciwomeData"}
            })
            window.open(routeData.href, "_blank")
        },
        change_selected_project(){
            console.log('selected project', this.change_selected_project)
        },
        select_project(p){
            this.$parent.selected_project_id = p
        }
    },

}
</script>

<style>
    .white-table{
        /* background:#fefde3; */
        color:#1f2d3b!important;
    }
    .white-table  th{
        color:#1f2d3b!important;
    }
    .white-table tr:hover{
        background:#f2f7ff!important;
    }
    .left-dashboard-table-wrap{
        height:calc(100vh - 190px); 
        overflow-y:scroll;
    }
</style>
